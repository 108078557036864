<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="redtext">Clearment Vertragsschutz</h1>
            <h2 class="mt-2">
              Die "Versicherung" für Ihre Zahnzusatzversicherung
            </h2>
            <div class="mt-2">
              Der Clearment Vertragsschutz
              <a href="https://www.clearment.de" target="_blank"
                >www.clearment.de</a
              >
              <b> sichert</b> Ihre vertraglich zugesicherten Leistungen ab.
              Damit Ihre Versicherung auch wirklich leistet, wenn Sie sich
              brauchen.<br /><br />

              <h3>
                Wir lesen für Sie das
                <span class="redtext">Kleingedruckte</span>
              </h3>
              Die Clearment Anspruchsprüfer arbeiten sich für Sie durch das
              „Kleingedruckte“ und ermitteln Ihre berechtigten
              Vertragsansprüche. Anhand des von uns entwickelten
              <a @click="showCheck = !showCheck">Clearment VertragsChecks</a>
              wissen unsere Prüfer genau welche vertraglichen Ansprüche Ihnen
              zustehen. <br /><br />
              <h3>
                Wir hängen uns für Sie in die
                <span class="redtext">Warteschleife</span>
              </h3>
              Das Clearment Team telefoniert für Sie mit den
              Leistungsabteilungen der Versicherung und setzt Ihre vertraglichen
              Ansprüche durch. Sie ersparen Sich so unnötige Zeit und unnötigen
              Ärger. Das Clearment Team hilft Ihnen schnell und sicher Ihre
              Erstattung schnell und sicher zu erhalten.
              <br /><br />
              <h3>
                Wir belegen Ihre
                <span class="redtext">vertraglichen Ansprüche</span>
              </h3>
              Bei strittigen Leistungsfragen können wir Ihre Ansprüche mit Hilfe
              des Clearment VertragsChecks exakt belegen. Im Rahmen des
              Clearment VertragsChecks lassen wir uns von den Versicherung 157
              Fragen zur Leistung und zum Vertrag beantworten. Im Streitfall
              wissen wir dadurch genau wieviel Erstattung Ihnen wirklich
              zusteht. Und können dies auch belegen.

              <div class="mt-8 pa-4" style="background-color: #f2f2f2">
                <em
                  >„...das Clearment Vertragsschutz Team sahen mich nach
                  Sichtung der Unterlagen voll im Recht und managten diese für
                  mich sehr ärgerliche Angelegenheit umfassend und souverän. Sie
                  berieten mich in Bezug auf eine von mir einzuholende
                  Stellungnahme und baten um Nachprüfungen. Schließlich
                  reklamierten Sieden Bescheid an höchster Stelle, beim Vorsitz
                  der Versicherung, so dasswir nun einen 100prozentigen Erfolg
                  verzeichnen können. Die Versicherung hat die Kostenübernahme
                  für alle 9 Zähne zugesichert...“</em
                ><br /><br />
                <b>Stefan K. , Stuttgart WaizmannTabelle Kunde</b>
              </div>
            </div>
            <v-row class="mt-4">
              <v-col cols="12">
                <h2>
                  <span class="redtext"
                    >Clearment setzt Ihre Ansprüche durch</span
                  >
                </h2>
                <v-timeline align-top dense>
                  <v-timeline-item
                    dot-color="grey"
                    v-for="item in pruefungen"
                    :key="item.pruef_id"
                    small
                  >
                    <v-row>
                      <v-col>
                        <span
                          ><strong class="text-green"
                            >{{ item.pruef_ergebnis_value }} €</strong
                          >

                          | Nacherstattung</span
                        >
                        <div class="pl-12">
                          <!-- <div class="caption">
                            Rechnung: {{ item.pruef_text }}
                            <br />

                            Vertrag: {{ item.un_name }}
                            {{ item.tarif_title }}
                          </div> -->
                          <div class="caption">
                            <span v-if="item.pruef_ergebnis != 0"
                              >Ergebnis:</span
                            >

                            <span
                              class="text-orange"
                              v-if="item.pruef_ergebnis == 0"
                            >
                              Anspruch wird gerade geprüft
                            </span>
                            <span
                              class="text-green"
                              v-if="item.pruef_ergebnis == 1"
                            >
                              Vertraglicher Erstattungsanspruch vorhanden.
                              <span v-if="item.pruef_ergebnis_value > 0"
                                >Es wurden
                                <b class="text-green"
                                  >{{ item.pruef_ergebnis_value }} €</b
                                >
                                nacherstattet.</span
                              >
                            </span>
                            <span
                              class="red--text"
                              v-if="item.pruef_ergebnis == 2"
                            >
                              Erstattung wurde zurecht
                              <span v-if="item.pruef_type == 1">abgelehnt</span>
                              <span v-if="item.pruef_type == 2">gekürzt</span>
                            </span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12" md="2">
            <img width="50px" :src="require('@/assets/clearment/small.png')" />
          </v-col>
          <v-col cols="12" md="10" class="pt-4">
            Als Waizmann-Kunde erhalten Sie den Clearment Vertragsschutz
            <b class="text-green">kostenlos</b>
            . Während der gesamten Vertragslaufzeit.
            <br /><br />
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="12" md="6">
            <img
              :src="require('@/assets/clearment/anwalt.png')"
              style="
                width: 180px;
                height: 180px;
                float: left;
                padding-right: 4px;
              "
            />
          </v-col>

          <v-col cols="12" md="6">
            <br />
            <b>Florian Ferdinand</b>
            <span>
              Ihr persönlicher Clearment Anspruchsprüfer seit 2007. 1892
              erfolgreiche Reklamationen.
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const pruefungen = ref([
    {
      pruef_id: 1,
      pruef_text: "Überhöhter Abrechnungsfaktor für eine Wurzelbehandlung",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 50,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 4,
      pruef_text: "Unberechtigter Abzug des fiktiven GKV-Anteils",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 510,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 45573,
      pruef_text: "Ablehnung Fissurenversiegelung für Kinder",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 90,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 3,
      pruef_text: "Ablehnung der Leistung für Digitale Volumentomographie",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 260,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 6,
      pruef_text:
        "Analoge Abrechnung einer Sedierung für eine Implantatversorgung",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 174,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 2,
      pruef_text: "Ablehnung der Kosten für die Verblendung des 7er-Zahns",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 110,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 8,
      pruef_text:
        "Abrechnungsfehler bei der Dunkelverarbeitung durch die Versicherung",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 230,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 5,
      pruef_text: "Überhöhte Verrechnung von Material und Laborkosten",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 670,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 7,
      pruef_text: "Leistungsabzug wegen nicht lückenlos gepflegten Bonusheft",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 315,
      pruef_ergebnis: 1,
    },
    {
      pruef_id: 45572,
      pruef_text: "Ablehnung von Begleitleistungen für Zahnersatz",
      un_name: "",
      tarif_title: "",
      pruef_ergebnis_value: 63,
      pruef_ergebnis: 1,
    },
  ]);

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
