import { createRouter, createWebHistory } from "vue-router";
// import { isEqual } from "lodash";
import RechnerView from "../views/rechner/RechnerView.vue";
import AllerechnerView from "../views/rechner/AllerechnerView.vue";
import ErgebnisView from "../views/rechner/ErgebnisView.vue";
import AntragfragenView from "../views/rechner/AntragfragenView.vue";
import PersonalfragenView from "../views/rechner/PersonalfragenView.vue";
import AntragOkView from "../views/rechner/AntragOkView.vue";
import SignUp from "../views/rechner/SignUp.vue";
import TestIndex from "../views/rechner/TestIndex.vue";
//seo

const routes = [
  {
    path: "/testindex",
    name: "testindex",
    component: TestIndex,
  },
  {
    path: "/rechner/:userid/:bertype?",
    name: "rechner",
    component: RechnerView,
  },
  {
    path: "/vergleich/starten/:bertype?",
    name: "rechnerohneid",
    component: RechnerView,
  },
  {
    path: "/zzv/:userid",
    name: "zzv",
    component: AllerechnerView,
  },
  {
    path: "/start/:userid",
    name: "allerechner",
    component: AllerechnerView,
  },
  {
    path: "/ostart/:userid/:sub?",
    name: "ostart",
    component: AllerechnerView,
  },

  {
    path: "/signup/:token/:tarif_id",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/vergleich/ergebnis/:token",
    name: "ergebnis",
    component: ErgebnisView,
  },
  {
    path: "/angebot/kostenlos/:tarif_id/:token",
    name: "antragfragen",
    component: AntragfragenView,
  },
  {
    path: "/angebot/es_hat_geklappt/:tarif_id/:token",
    name: "antragfragenok",
    component: AntragOkView,
  },
  {
    path: "/angebot/kostenlos_fertig/:tarif_id/:token",
    name: "personalfragen",
    component: PersonalfragenView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
